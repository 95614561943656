.App {
  font-family: 'Arial', sans-serif;
  color: #333;
  text-align: center;
  background-color: #f9f9f9;
}

.header {
  background-color: #ff6347;
  color: white;
  padding: 20px;
}

.header h1 {
  font-size: 3rem;
  margin: 0;
}

.header p {
  font-size: 2rem;
  margin: 5px 0;
}

.hero {
  margin-top: 10px;
}

.hero-image {
  width: 100%;
  max-height: 600px;
  object-fit: cover;
}

.content-image {
  max-height: 500px;
  object-fit: cover;
}

.content {
  padding: 20px;
}

.section {
  margin: 20px 0;
  padding: 15px;
  border-bottom: 1px solid #ddd;
}

.section h2 {
  font-size: 2.2rem;
  color: #ff6347;
}

.section p {
  font-size: 1.5rem;
  line-height: 1.6;
}

.section ul {
  list-style-type: none;
  padding: 0;
  font-size: 1rem;
}

.section ul li {
  margin: 5px 0;
}

.footer {
  background-color: #333;
  color: white;
  padding: 15px;
  font-size: 0.9rem;
}

.Row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; /* Ensures content wraps on smaller screens */
}

.Column {
  flex: 1;
  padding: 10px;
}

/* Media query for screens 768px or smaller */
@media (max-width: 768px) {
  .Row {
    flex-direction: column; /* Stacks columns vertically on small screens */
  }
  
  .Column {
    padding: 10px 0; /* Reduces side padding on smaller screens */
  }

  .content-image {
    max-width: 100%; /* Ensures image doesn’t overflow */
    height: auto; /* Maintains aspect ratio */
  }

  .section h2 {
    font-size: 2.0rem;
    color: #ff6347;
  }
  
  .section p {
    font-size: 1.2rem;
    line-height: 1.6;
  }
}

/* FAQ Section */
.faq-section {
  padding: 20px;
  max-width: 1000px;
  margin: auto;
}

.faq-section h2 {
  font-size: 2.2rem;
  color: #ff6347;
  margin-bottom: 40px;
}

.faq-item {
  margin: 15px 0;
  text-align: left;
}

.faq-question {
  background-color: #ff6347;
  color: white;
  border: none;
  padding: 10px;
  width: 100%;
  text-align: left;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  font-size: 1.5rem;
  line-height: 1.6;
}

.faq-question:hover {
  background-color: #e5533b;
}

.faq-answer {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s ease;

  padding: 0px 10px 0px 10px;
  background-color: #e0e0e0;
  border-left: 3px solid #ff6347;
  margin-top: 5px;

  font-size: 1.3rem;
  line-height: 1.6;
}

.faq-answer.open {
  max-height: 210px; /* Set a max-height large enough for the text */

  padding: 0px 10px 0px 10px;
  background-color: #e0e0e0;
  border-left: 3px solid #ff6347;
  margin-top: 5px;

  font-size: 1.3rem;
  line-height: 1.6;
}

/* Timeline Styles */
.timeline {
  padding: 20px;
  max-width: 900px;
  margin: auto;
  text-align: left;
  position: relative;
}

.timeline h2 {
  text-align: center;
  color: #ff6347;
  font-size: 2rem;
  margin-bottom: 20px;
}

.timeline-event {
  padding-left: 20px;
  position: relative;
  margin-bottom: 20px;
}

.timeline-year {
  font-size: 1.2rem;
  font-weight: bold;
  color: #ff6347;
  margin-bottom: 5px;
}

.timeline-content {
  background: #f9f9f9;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 1.3rem;
}

.timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 15px; /* Position line slightly away from text */
  width: 3px;
  height: 100%;
  background: repeating-linear-gradient(
    180deg,
    #ff6347,
    #ff6347 10px,
    transparent 10px,
    transparent 20px
  );
}